import React from "react";
import tech from './images/tech.png'

export function Home() {

  return (
    <div className="home"> 
         <img src={tech}></img>
         <h2 style={{margin:"25px 0"}}>Bienvenido(a) a la plataforma de servicios MAV Digital </h2> 
         <p> <strong>MAV Digital</strong> es nuestra plataforma de operaciones.             
          Nuestra misión es digitalizar los servicios contables y utilizar herramientas 
             tecnológicas para agregar valor a nuestros clientes.
         </p>
    </div>
  )
  
}
