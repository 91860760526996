import React from "react";
import "./App.css";
import Amplify from "aws-amplify";
import {AmplifyAuthenticator, AmplifyGreetings, AmplifySignOut} from "@aws-amplify/ui-react";
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
import awsconfig from "./aws-exports";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Home } from "./Home";
import { Users } from "./Users";
import { Documents } from "./Documents";
import { Clients } from "./Clients"
import "bootstrap/dist/css/bootstrap.min.css";


Amplify.configure(awsconfig);

const App = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <Router>
      <AmplifyGreetings username={user.username}></AmplifyGreetings>
   <div className="App">
      <div className="app-header">
        <div className="navigation-menu">
          <div>
            <ul>
              <li>
                <Link to="/">Inicio</Link>
              </li>
            
             {/* Old route for clients and email management */}
              {/* <li>
                <Link to="/users">Usuarios</Link>
              </li> */}
            
              <li>
                <Link to="/documents">Documentos</Link>
              </li>
              <li>
                <Link to="/clients">Clientes</Link>
              </li>
            </ul>
            
          </div>
        </div>
        <div className="user-info">
          
        </div>
      </div>
      <Switch>
      <Route path="/users">
        <Users />
      </Route>
      <Route path="/documents">
        <Documents />
      </Route>
      <Route path="/clients">
        <Clients />
      </Route>  
        <Route path="/">
        <Home />
      </Route>  
               
    </Switch>
      
    </div>
    </Router>          

  ) : (
     <AmplifyAuthenticator />
  );
};

export default App;



