import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";


import { Button } from "react-bootstrap";

import {
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";

import { Messages } from "./Messages";
import { ClientDetails } from "./ClientDetails";

export function Users() {

  let { path, url } = useRouteMatch();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [columns, setColumns] = useState([
    {
      title: "Nombre",
      field: "name"
    },
    {
      title: "Email",
      field: "accountingEmail",
    },
    {
      name: 'Acciones',
      render: row => <div>
         <Button style={{ paddingLeft:"10px", paddingRight:"10px"}} ><Link to={{ pathname: `${url}/clientDetails`, search: `idNumber=${row.idNumber}` }} style={{color:"white", textDecoration:"none"}}>
          Detalle de cliente
          </Link></Button>
        <Button style={{marginLeft:"10px"}} ><Link to={{ pathname: `${url}/files`, search: `idNumber=${row.idNumber}&name=${row.name}` }} style={{color:"white", textDecoration:"none"}}>
          Ver Archivos
          </Link></Button>
      </div>
    }
  ]); 

  useEffect(() => {
    try {
      fetch('https://tlehe7w4be.execute-api.us-east-1.amazonaws.com/dev/users')
        .then(res => res.json())

        .then((result) => {
          setIsLoaded(true);
          setItems(result.Items);
          console.log(items);
        }, (error) => {
          setIsLoaded(true);
          setError(error);
        });

    } catch (error) {
      console.error(error);
    }
  }, []);

  if (error) {
    return (<div>Error: {error.message}</div>);
  } else if (!isLoaded) {
    return (<div>Loading...</div>);
  } else {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>

           {/* Commented feaature for now */}
        {/* <Button style={{marginLeft:"10px"}} ><Link to={{ pathname: `${url}/clientDetails` }} style={{color:"white", textDecoration:"none"}}>
          Agregar Usuario
          </Link></Button> */}

          <MaterialTable title="" data={items} columns={columns} />
        </div>

        <div style={{ width: "50%", marginTop: "50px" }}>
          <Switch>
            <Route exact path={path}>
              <b>* Seleccione Detalle de cliente para desplegar la información de un cliente.</b><br/>
              <b>* O Seleccione  Ver Archivos para ver los mensajes de correo y adjuntos del cliente.</b>
            </Route>
            <Route path={`${path}/files`}>
              <Messages></Messages>
            </Route>
            <Route path={`${path}/clientDetails`}>
              <ClientDetails></ClientDetails>
            </Route>
          </Switch>
        </div>
      </div>

    );
  }
}