import React, { useState, useEffect } from "react";
import  Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Accordion, Card, Button } from "react-bootstrap";
import { Files } from "./Files";

import {
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";


const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
] ;

const API_BASE_URL = 'https://tlehe7w4be.execute-api.us-east-1.amazonaws.com/dev';

export function Documents() {

  let { path, url } = useRouteMatch();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [totalAttachments, setTotalAttachments] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [totalXML, setTotalXML] = useState(0);
  const [totalPDF, setTotalPDF] = useState(0);
  const [totalOthers, setTotalOthers] = useState(0);

  const changeSelected = function(selectedIdNumber){
    setSelectedClient((items.filter(e => e.idNumber == selectedIdNumber))[0]);
  }

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
   setMessages([]);
   setError(null);
  }, [selectedClient]);


  useEffect(() => {
    try {
      fetch('https://tlehe7w4be.execute-api.us-east-1.amazonaws.com/dev/users')
        .then(res => res.json())

        .then((result) => {
          setIsLoaded(true);
          setSelectItems(result.Items.map(function(item){
            return {value: item.idNumber, label: `${item.name}`}
          }));
          setItems(result.Items);
          console.log(items);
        }, (error) => {
          setIsLoaded(true);
          setError(error);
        });

    } catch (error) {
      console.error(error);
    }
  }, []);

  if (error) {
    return (<div>Error: {error.message}</div>);
  } else if (!isLoaded) {
    return (<div>Loading...</div>);
  } else {
    return (
      <div >
        <div style={{margin: "20px 40%"}} >
        <Select options={selectItems}  placeholder='Busque y Seleccione un cliente' 
          onChange={(e) => changeSelected(e.value)}/>
        
        </div>
        <span> selected Email : {selectedClient.accountingEmail} </span>
        <div style={{ width: "90%", marginLeft: "5%" }}>
          <div style={{display:"flex", margin:"25px 10%"}}>
            <span>Desde: <DatePicker selected={startDate} onChange={date => setStartDate(date)}  dateFormat="dd/MM/yyyy"/></span>
            <span style={{marginLeft:"15px"}} >Hasta : <DatePicker selected={endDate} onChange={date => setEndDate(date)} dateFormat="dd/MM/yyyy" /></span>
            <Button  variant="primary" style={{marginLeft:"25px"}} onClick={() => {
                try {
                  if(startDate == undefined || endDate == undefined || endDate < startDate){
                    alert('Fechas inválidas, debe seleccionar un rango de fechas válido.');
                  }else{
                    setIsLoading(true);
                    setError(null);
                    setMessages([]);

                    var startDateSeconds = startDate.getTime()/1000;
                    var endDateSeconds = endDate.getTime()/1000 + 60*60*24; //Add 1 day to end date to make the search inclusive on the end date filter 
                    
                    const url = `${API_BASE_URL}/attachments/${selectedClient.idNumber}?after=${startDateSeconds}&before=${endDateSeconds}`;

                    fetch(url)
                    .then(res => res.json())
                    .then((result) => {
                        var responseBody = JSON.parse(result.body);
                        if (responseBody.error) {
                          setError(JSON.stringify(responseBody.error));
                        } else {
                          setMessages(responseBody.messages);

                          var _totalAttachments = 0, _totalMessages = 0, _totalPDF = 0, _totalXML = 0, _totalOthers = 0;

                          responseBody.messages.forEach(message => {
                            _totalMessages = _totalMessages + 1;
                            _totalAttachments = _totalAttachments + message.attachments.length;
                            message.attachments.forEach(attachment => {
                              if(attachment.filename.endsWith('pdf') || attachment.filename.endsWith('PDF')){
                                _totalPDF = _totalPDF + 1 ;
                              }else if (attachment.filename.endsWith('xml') || attachment.filename.endsWith('XML')){
                                _totalXML = _totalXML + 1 ;
                              }else{
                                _totalOthers = _totalOthers;
                              }
                            });
                          });

                          setTotalMessages(_totalMessages);
                          setTotalAttachments(_totalAttachments);
                          setTotalPDF(_totalPDF);
                          setTotalXML(_totalXML);
                          setTotalOthers(_totalOthers);
                        }
                        setIsLoading(false);
                      }, (error) => {
                        setError(error);
                        setIsLoading(false);
                    });
                  } 
                } catch (error) {
                  console.error(error);
                  setIsLoading(false);
                  setError(error);
                }
            }
              
            }>Ver mensajes</Button>
          </div>
          
          {isLoading ? ("Cargando archivos..."): 
          (
            error ? (<p>app errror : {error}</p>
            ) :
              (
                messages.length > 0 ?
                  (<div >
                    <Button variant="primary" size="md"  style={{ margin: "25px", padding: "15px" }} 
                      onClick={() => {
                        messages.forEach(function(message){
                          message.attachments.forEach(function (attachment){
                            if(attachment.filename.endsWith('pdf') || attachment.filename.endsWith('PDF')){
                              downloadAttachment(attachment, selectedClient.idNumber)
                            }
                          });
                        });
                      }} 
                    > Descargar PDFs solamente 
                    </Button>
                    <Button variant="primary" size="md" style={{ margin: "25px", padding: "15px" }}
                      onClick={() => {

                        downloadAllAttachments(messages, selectedClient.idNumber);
                      }} 
                    > Descargar todos los archivos
                    </Button>
                    <span>{totalMessages} mensajes , {totalAttachments} adjuntos {totalPDF} PDFs, {totalXML} XMLs, {totalOthers} otros.</span>
                    <Accordion>
                      {messages.map((message, index) => {
                        var date = new Date(message.date);
                        return (
                          <Card style={{marginBottom:"2px"}}>
                            <Accordion.Toggle as={Card.Header} eventKey={index+1}>
                              <span style={{ float: "left" }}> {index + 1} - {date.toLocaleDateString()}</span>
                              <span style={{ float: "right" }}> {message.attachments.length} adjuntos</span>
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey={index+1}>
                              <Card.Body>
                                <p>
                                  {message.subject}
                                </p>
                                <Files attachments={message.attachments} idNumber={selectedClient.idNumber}></Files>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                        );
                      })}
                    </Accordion>

                    </div>
                  ) :
                  (<p>No hay mensajes para mostrar, selecciones un rango de fechas válido y persione <b>Ver mensajes</b>.</p>)
              )
            ) 
          }
        </div>
      </div>

    );
  }
}

async function downloadAllAttachments(messages, idNumber){
  for (let messageIndex = 0; messageIndex < messages.length; messageIndex++) {
    console.log("start message ", messageIndex + 1);
    const message = messages[messageIndex]; 
    for (let attachmentIndex = 0; attachmentIndex < message.attachments.length; attachmentIndex++) {
      const attachment = message.attachments[attachmentIndex];
      await downloadAttachmentV2(attachment, idNumber, messageIndex, attachmentIndex);
    }
  }
}

function downloadAttachment(attachment, idNumber){
  let DOWNLOAD_URL = `${API_BASE_URL}/attachments/${idNumber}/download?messageId=${attachment.messageId}&attachmentId=${attachment.attachmentId}`;
  fetch(DOWNLOAD_URL)
  .then(result => result.json())
  .then((res) => {

    var byteString = atob(res);

  // Convert that text into a byte array.
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var blob = new Blob([ia], { type: "octet/stream" });
    var url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = attachment.filename;
    a.click();
    window.URL.revokeObjectURL(url);

  });
}


async function downloadAttachmentV2(attachment, idNumber, messageIndex, attachmentIndex){
  console.log("descargando mensaje ", messageIndex, " attachmentIndex ", attachmentIndex);
  console.log(attachment);
  let DOWNLOAD_URL = `${API_BASE_URL}/attachments/${idNumber}/download?messageId=${attachment.messageId}&attachmentId=${attachment.attachmentId}`;
  const response = await fetch(DOWNLOAD_URL);

  const res = await response.json();
  var byteString = atob(res);

  // Convert that text into a byte array.
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var blob = new Blob([ia], { type: "octet/stream" });
    var url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = attachment.filename;
    a.click();
    window.URL.revokeObjectURL(url);

    console.log("descarga completa mensaje ", messageIndex, " attachmentIndex ", attachmentIndex);
}