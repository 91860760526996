import React, { useState, useEffect } from "react";
import { Button, Form, FormCheck } from "react-bootstrap";
import {
  useLocation
} from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";


const API_BASE_URL = 'https://tlehe7w4be.execute-api.us-east-1.amazonaws.com/dev';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function ClientDetails(props) {
  const [client, setClient] = useState();
  const [isActive, setIsActive] = useState(true);
  const [startDate, setStartDate] = useState(new Date());

  const query = useQuery();
  const idNumber = query.get('idNumber');
  const name = query.get('name');

  useEffect(() => {
    setStartDate(null);
  }, [idNumber]);

  useEffect(() => {
    try {
      fetch(`${API_BASE_URL}/users/${idNumber}`)
        .then(res => res.json())

        .then((result) => {
          setClient(result.Item);
          console.log(JSON.stringify(result));
        }, (error) => {
        });

    } catch (error) {
      console.error(error);
    }
  }, [idNumber]);

  return (
    <div style={{ width: "90%", marginLeft: "5%" }}>
      <h3>Detalle de cliente</h3>
      <Form style={{ textAlign: "left" }}>
        <Form.Group>
          <Form.Label>Nombre:</Form.Label>
          <Form.Control type="text"
            placeholder="Ingrese nombre completo" value={client?.name} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Cédula:</Form.Label>
          <Form.Control type="text"
            placeholder="Ingrese número de cédula (Física o Jurídica según corresponda)" value={client?.idNumber} />
        </Form.Group>
        <Form.Group>
          <span style={{ marginRight: "5px" }}>Fecha de ingreso:</span>
          <DatePicker selected={startDate} onChange={date => setStartDate(date)} dateFormat="dd/MM/yyyy" />
          <span style={{ marginLeft: "50px", marginRight: "15px" }}>Estado:</span>

          <FormCheck
            type="radio" inline
            checked={isActive}
            onChange={() => {
              if (isActive) {
                setIsActive(false);
              } else {
                setIsActive(true)
              }
            }}
            label={isActive ? (<span><strong>Activo</strong></span>) : (<span>Activo</span>)} />
          <FormCheck
            type="radio" inline
            checked={!isActive}
            onChange={() => {
              if (isActive) {
                setIsActive(false);
              } else {
                setIsActive(true)
              }
            }}
            label={isActive ? (<span>Inactivo</span>) : (<span><strong>Inactivo</strong></span>)
            } />
        </Form.Group>
        <Form.Group>
          <Form.Label>Correo personal:</Form.Label>
          <Form.Control type="email"
            placeholder="Correo personal" value={client?.personalEmail} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Telefono:</Form.Label>
          <Form.Control type="text" placeholder="Ingrese el teléfono de contacto" value={client?.phone}/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Negocio:</Form.Label>
          <Form.Control type="text"
            placeholder="Ingrese el nombre de la empresa o actividad económica" value={client?.business} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Monto de la membresía:</Form.Label>
          <Form.Control type="number"
            placeholder="Monto de la memembresía en colones" value={client?.membershipRate} />
        </Form.Group>
        <Form.Group>
          <Form.Label >Régimen:</Form.Label>
          <Form.Control as="select" value={client?.regime} defaultValue="Seleccione un  régimen">
            <option value="">Seleccione un  régimen</option>
            <option value="Tradicional-Físicas">Tradicional-Físicas</option>
            <option value="Tradicional-Jurídicas IVA">Tradicional-Jurídicas IVA</option>
            <option value="Simplificado">Simplificado</option>
            <option value="REA">REA</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Correo para facturas:</Form.Label>
          <Form.Control type="email"
            placeholder="Correo de factura electrónica" value={client?.accountingEmail} />
        </Form.Group>

        <Button style={{ marginTop: "10px", float: "right" }} variant="primary" size="lg" onClick={()  => {
          alert("La funcionalidad de Guardar cambios está en desarrollo y será entregada próximamente.")} } >
          Guardar cambios
        </Button>
      </Form>
      {/* <span>Correo personal: </span> <input type="text"></input>
        <span>Correo para factura electrónica: </span> <input type="text"></input> */}

    </div>
  );
}