import React, { useState, useEffect } from "react";
import { Button, Form, FormCheck, Alert } from "react-bootstrap";
import Select from 'react-select';

const API_BASE_URL = 'https://tlehe7w4be.execute-api.us-east-1.amazonaws.com/dev';
const API_CLIENTS_URL_V2 = 'https://hiolx872c2.execute-api.us-east-1.amazonaws.com/';

export function Clients() {

  const [selectedClient, setSelectedClient] = useState();

  const [selectItems, setSelectItems] = useState([]);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  // const [showClientDetails, setShowClientDetails] = useState(false);

  const [name, setName] = useState();
  const [idNumber, setIdNumber] = useState();

  const [idNumberType, setIdNumberType] = useState();

  const [startDate, setStartDate] = useState();
  const [isActive, setIsActive] = useState();
  const [personalEmail, setPersonalEmail] = useState();
  const [phone, setPhone] = useState();
  const [business, setBusiness] = useState();

  const [membershipRate, setMembershipRate] = useState();
  const [membershipCurrency, setMembershipCurrency] = useState();

  const [regime, setRegime] = useState();
  const [accountingEmail, setAccountingEmail] = useState();
  const [contactName, setContactName] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  const handleSelectChange = (value) => {
    console.log("handle change 2 value : ", value);
    setSelectedClient(value);
    setClientProperties();
    setClientProperties((items.filter(e => e.idNumber == value.value))[0]);
  };

  const setClientProperties = (client) => {
    if (client) {
      setName(client.name ?? '');
      setIdNumberType(client.idNumberType ?? ''); 
      setIdNumber(client.idNumber ?? '');
      setStartDate(client.startDate ?? '');
      setIsActive(client.isActive ?? '');
      setPersonalEmail(client.personalEmail ?? '');
      setPhone(client.phone ?? '');
      setContactName(client.contactName ?? '');
      setBusiness(client.business ?? '');
      setMembershipRate(client.membershipRate ?? '' );
      setMembershipCurrency(client.membershipCurrency ?? '');
      setRegime(client.regime ?? '');
      setAccountingEmail(client.accountingEmail ?? '');
    } else {
      setName('');
      setIdNumberType('');
      setIdNumber('');
      setStartDate('');
      setIsActive(true);
      setPersonalEmail('');
      setPhone('');
      setContactName('');
      setMembershipRate('');
      setMembershipCurrency('');
      setRegime('');
      setAccountingEmail('');
      setBusiness('');
    }
  }

  const saveClient = async () => {
    let payload = {      
      idNumber: idNumber,
      idNumberType : idNumberType,
      name: name,
      startDate: startDate,
      isActive: isActive,
      personalEmail: personalEmail,
      phone: phone,
      contactName: contactName,
      business: business,
      membershipRate: membershipRate,
      membershipCurrency : membershipCurrency,
      regime: regime,
      accountingEmail: accountingEmail
    }

    const response = await fetch(API_CLIENTS_URL_V2, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    showAlertMessage("Información de cliente actualizada corerctamente");
  }

  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setShowMessage(true);
    setTimeout(hideMessage, 5000);
  }

  const hideMessage = (message) => {
    setShowMessage(false);
    setAlertMessage(null);
  }


  useEffect(() => {
    try {
      fetch('https://tlehe7w4be.execute-api.us-east-1.amazonaws.com/dev/users')
        .then(res => res.json())

        .then((result) => {
          setIsLoaded(true);
          setSelectItems(result.Items.map(function (item) {
            return { value: item.idNumber, label: `${item.name}` }
          }));
          setItems(result.Items);
          console.log(items);
        }, (error) => {
          setIsLoaded(true);
          setError(error);
        });

    } catch (error) {
      console.error(error);
    }
  }, []);

  if (error) {
    return (<div>Error: {error.message}</div>);
  } else if (!isLoaded) {
    return (<div>Loading...</div>);
  } else {
    return (
      <div>
        <Alert variant="success" style={{ display: showMessage ? "block" : "none" }}>
          {alertMessage}
        </Alert>
        <div style={{ width: "80%", margin: "15px auto" }}>
          <div style={{ width: "50%", margin: "0 auto 50px auto", display:"flex" }}>
            <div style={{width:"50%"}}>
              <Select options={selectItems} placeholder='Seleccione un cliente' value={selectedClient} style={{ width: "90%" }}
                  onChange={handleSelectChange}
                />
            </div>
            <div style={{width:"25%"}}>
              <Button onClick={() => {
                  setSelectedClient(null);
                  setClientProperties();
                }}>Nuevo cliente</Button>
            </div>
          </div>
          <div>
            <Form >
              <Form.Group>
                <div style={{display:"flex"}}>
                  <div style={{width:"20%",  textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Nombre</Form.Label>
                  </div>
                  <div style={{width:"40%"}}>
                  <Form.Control type="text"
                    placeholder="Ingrese nombre completo de nuevo cliente" value={name} onChange={(event) => { setName(event.target.value) }} />
                  </div>
                  <div style={{width:"30%"}}>
                    <FormCheck
                      type="radio" inline
                      checked={isActive}
                      onChange={() => {
                        setIsActive(!isActive);
                      }}
                      label={isActive ? (<span><strong>Activo</strong></span>) : (<span>Activo</span>)} />
                    <FormCheck
                      type="radio" inline
                      checked={!isActive}
                      onChange={() => {
                        setIsActive(!isActive);
                      }}
                      label={isActive ? (<span>Inactivo</span>) : (<span><strong>Inactivo</strong></span>)
                      } />
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <div style={{display:"flex"}}>
                  <div style={{width:"20%", textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Correo</Form.Label>
                  </div>
                  <div style={{width:"40%"}}>
                    <Form.Control type="text"
                      placeholder="Ingrese el correo personal" 
                      value={personalEmail} onChange={(value) => { setPersonalEmail(value.target.value) }} />
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <div style={{display:"flex"}}>
                  <div style={{width:"20%",  textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Tipo de Cédula</Form.Label>
                  </div>
                  <div style={{width:"30%"}}>
                    <Form.Control as="select" value={idNumberType} onChange={(event) => setIdNumberType(event.target.value)}>
                    <option value="">Seleccione</option>
                    <option value="Física">Física</option>
                    <option value="Jurídicas">Jurídicas</option>
                    <option value="NITE">NITE</option>
                    </Form.Control>
                  </div>
                  <div style={{width:"20%", textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Cédula</Form.Label>
                  </div>
                  <div style={{width:"30%"}}>
                    <Form.Control type="text" 
                      placeholder="Ingrese número de cédula"
                      value={idNumber} onChange={(value) => { setIdNumber(value.target.value) }} />
                  </div>

                </div>
              </Form.Group>

              <Form.Group>
                <div style={{display:"flex"}}>
                  <div style={{width:"20%", textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Teléfono</Form.Label>
                  </div>
                  <div style={{width:"30%"}}>
                    <Form.Control type="text" placeholder="Ingrese el teléfono de contacto" value={phone} onChange={(event) => setPhone(event.target.value)} />
                  </div>
                  <div style={{width:"20%", textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Fecha de ingreso</Form.Label>
                  </div>
                  <div style={{width:"30%"}}>
                    <Form.Control type="text"
                        placeholder="Ingrese la fecha de ingreso" 
                        value={startDate} onChange={(value) => { setStartDate(value.target.value) }} />
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <div style={{display:"flex"}}>
                  <div style={{width:"20%", textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Nombre de contacto</Form.Label>
                  </div>
                  <div style={{width:"40%"}}>
                    <Form.Control type="text"
                      placeholder="Ingrese el nombre de contacto" 
                      value={contactName} onChange={(value) => { setContactName(value.target.value) }} />
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <div style={{display:"flex"}}>
                  <div style={{width:"20%", textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Régimen</Form.Label>
                  </div>
                  <div style={{width:"30%"}}>
                    <Form.Control as="select" value={regime} onChange={(event) => setRegime(event.target.value)}>
                      <option value="">Seleccione</option>
                      <option value="Tradicional-Físicas">Tradicional-Físicas</option>
                      <option value="Tradicional-Renta">Tradicional-Renta</option>
                      <option value="Tradicional-Jurídicas IVA">Tradicional-Jurídicas IVA</option>
                      <option value="Simplificado">Simplificado</option>
                      <option value="REA">REA</option>
                    </Form.Control>
                  </div>
                  <div style={{width:"20%",  textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Tipo de empresa</Form.Label>
                  </div>
                  <div style={{width:"30%"}}>
                  <Form.Control type="text"
                    placeholder="Ingrese el tipo de empresa" value={business} onChange={(event) => setBusiness(event.target.value)} />
                  </div>

                </div>
              </Form.Group>
              <Form.Group>
                <div style={{display:"flex"}}>
                 
                  <div style={{width:"20%",  textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Monto de la membresía</Form.Label>
                  </div>
                  <div style={{width:"20%"}}>
                  <Form.Control type="number"
                    placeholder="Ingrese un monto" value={membershipRate} onChange={(event) => setMembershipRate(event.target.value)} />
                  </div>
                  <div style={{width:"20%", paddingLeft:"10px"}}>
                    <Form.Control as="select" value={membershipCurrency} onChange={(event) => setMembershipCurrency(event.target.value)}>
                      <option value="">Seleccione</option>
                      <option value="Dólares">Dólares</option>
                      <option value="Colones">Colones</option>
                    </Form.Control>
                  </div>
                </div>
              </Form.Group>

              <Form.Group>
                <div style={{display:"flex"}}>
                  <div style={{width:"20%", textAlign:"right", paddingRight: "15px"}}>
                    <Form.Label>Correo de facturación</Form.Label>
                  </div>
                  <div style={{width:"40%"}}>
                  <Form.Control type="email"
                  placeholder="Correo de factura electrónica" value={accountingEmail} onChange={(event) => setAccountingEmail(event.target.value)} />
                  </div>
                </div>
              </Form.Group>

              <Button style={{ marginTop: "10px", float: "right" }} variant="primary" size="lg" onClick={() => {
                saveClient();
              }} >
                Guardar cambios
              </Button>
            </Form>
          </div>
        </div>
      </div>

    );
  }
}