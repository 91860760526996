import React from "react";


export function Files(props) {
  const API_BASE_URL = 'https://tlehe7w4be.execute-api.us-east-1.amazonaws.com/dev';

  return (
    <div>
      {props.attachments.map(function (file) {

        var DOWNLOAD_URL = `${API_BASE_URL}/attachments/${props.idNumber}/download?messageId=${file.messageId}&attachmentId=${file.attachmentId}`;
        var DOWNLOAD_TEXT = file.filename.endsWith("xml") ? "XML" : file.filename.endsWith("pdf") || file.filename.endsWith("PDF") ? "PDF" : "Descargar";
        return (
          <button style={{ marginLeft: "10px", padding: "5px" }}
            onClick={() => {
              fetch(DOWNLOAD_URL)
                .then(result => result.json())
                .then((res) => {
                  console.log(res);

                  var byteString = atob(res);

                  // Convert that text into a byte array.
                  var ab = new ArrayBuffer(byteString.length);
                  var ia = new Uint8Array(ab);
                  for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                  }

                  var blob = new Blob([ia], { type: "octet/stream" });
                  var url = window.URL.createObjectURL(blob);

                  var a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  a.href = url;
                  a.download = file.filename;
                  a.click();
                  window.URL.revokeObjectURL(url);

                });
            }}> {DOWNLOAD_TEXT}
          </button>
        );

      })}
    </div>);
}
